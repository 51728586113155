/*==================
slick
==================*/

.section {
    display: none;
}
  * {
    box-sizing: border-box;
  }
  .sliderArea {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 25px;
  }
  .sliderArea.w300 {
    max-width: 300px;
  }
  .slick-slide {
    margin: 0 5px;
  }
  .slick-slide img {
    width: 100%;
    height: auto;
  }
  .slick-prev, .slick-next {
    z-index: 1;
  }
  .slick-prev:before, .slick-next:before {
    color: #000;
  }
  .slick-next:before {
      content: url(../images/slick-next.png);
      position: relative;
      bottom: 45px;
      right: 30px;
  }
  .slick-prev:before {
    content: url(../images/slick-prev.png);
    position: relative;
    left: 30px;
    bottom:43px;
  }
  .slick-slide {
    transition: all ease-in-out .3s;
    // opacity: .2;
  }
.slick-dots li button {
    display: none;
}
  .slick-active {
    // opacity: 1;
  }
  .slick-current {
    // opacity: 1;
  }
  .thumb {
    margin: 20px 0 0;
  }
  .thumb .slick-slide {
    cursor: pointer;
  }
  .thumb .slick-slide:hover {
    opacity: .7;
  }
  
  /*==================
  .full-screen
  ==================*/
  
  .full-screen_one .slick-list {
    overflow: visible;
  }
  .full-screen_one.slider {
    max-width: 300px;
    margin: 0 auto;
  }
  
  /*==================
  以下は不要です。
  ==================*/
  
  @media screen and (max-width: 1024px) {
    body {
      font-size: 14px;
    }
  }
  .section {
    max-width: 100%;
    margin: 0 auto;
    padding: 30px 0;
    overflow: hidden;
  }
  .section p._a {
    font-size: 12px;
    font-weight: bold;
    margin: 30px 0 0;
  }
  