@media(max-width:960px) {

    .header {

        &.triggered {
            background-color:#ffffff;
            mix-blend-mode: unset;
          }

        &__nav {
            display: none;
        }
    }

    .mobile-menu {

        &__btn {
            display: block;
            width: 80px;
            height: 80px;
            background-color: #2d5a6e;
        }
    }

    .hero {
        padding-top: 80px;

        &__title {
            font-size: 27px;
        }
    }


    .info {
        &__panel {
            margin-left: 50px;
        }
    }

    .category {
        &__inner {
            width: 100%;
        }

        &__images {
            width: 100%;
        }

        &__panel {
            margin: 12px 0;
            top: -20px;
        }

        &__cover {
            width: 100%;
        }

        &__txt {
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__item {
            & > img {
                height: 200px;
                object-fit: cover;
            }
        }
        &__parallax {
            margin-top: 60px;
          
            &::after {
                content: '';
                display: block;
                padding-top: 35%;
            }
        }
    }

    .store {
        &__wrapper {
            flex-direction: column;
            width: 100%;
            padding-top: 30px;
            margin: 0 auto;
        }

        &__images {
            width: 60%;

            & > img {
                width: 100%;
            }
        }

        &__des {
            padding-left: 0;
            width: 400px;
            position: relative;
            margin-top: 80px;

            & > img {
                position: absolute;
                top: -20%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &__content {
            padding-left: 13px;
            padding-bottom: 10px;
        }
    }

    .icon {
        width: 80%;

        &__p {
            padding-left: 0;
        }

        &__txt {
            padding-left: 0;
            & > span {
                padding-left: 0;
            }
        }
        &__wrapper {
            flex-direction: column;
            align-items: center;
        }

        &__left {
            width: 90%;
            margin-left: 0;
            margin-top:30px ;
        }
       
    }

    .top {
        &__news {
            &::after {
                padding-top: 40%;
            }
        }
    }

    .accordion_cover {
        width: 100%;
    }
   

    .accordion__title {
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);
    }

    .accordion_title-wrapper {
        left: 50%;
    }
    .accordion_one:nth-child(2n) .accordion_header > .accordion_title-wrapper {
        right: 50%;
    }
    .box_one-image01 {
        & > img {
            height: 180px;
        }
    }
    .box_one-image02 {
        & > img {
            height: 180px;
        }
    }
}