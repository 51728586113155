html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Sawarabi Gothic', sans-serif;
}

*:focus {
    outline: none;
  }

.fo-zo {
    font-family: 'ZCOOL XiaoWei', serif;
}
.fo-go {
    font-family: 'Sawarabi Gothic', sans-serif;
}

img {
    max-width: 100%;
    vertical-align: bottom;
}
a {
    color: black;
}
a:hover {
    text-decoration: none;
}

.mb-md {
    margin-bottom: 50px;
}

.float-right {
    float: right;
}

#info,#category,#service,#store {
    padding-top: 80px;
    margin-top: -80px;
}

// 共通タイトル
.section {
    &__title {
        &-wrapper {
            background-color: #2D5A6E;
            height: 80px;
            width: 70%;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 80px;
            position: relative;
            z-index: 2;

            &-category {
                background-color: #2D5A6E;
                height: 80px;
                width: 70%;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0 80px;
                position: relative;
                z-index: 2;
            }
        }

        &-left {
            display: flex;
            align-items: center;

            &-category {
                display: flex;
                align-items: center;

            }
        }
    }

    &__jattl {
        font-family: 'ZCOOL XiaoWei', serif;
        font-size: 40px;
        color: #ffffff;
        margin-left: 20px;

        &-category {
            font-family: 'ZCOOL XiaoWei', serif;
            font-size: 40px;
            color: #ffffff;
            margin-left: 20px;
        }
    }
    &__enttl {
        font-family: 'Sawarabi Gothic', sans-serif;
        font-size: 20px;
        color: #ffffff;
        margin-left: 40px;
        position: relative;
        top: 7px;

        &-category {
            font-family: 'Sawarabi Gothic', sans-serif;
            font-size: 14px;
            color: #ffffff;
            margin-left: 35px;
            position: relative;
            top: 7px;
        }
    }
}


.mobile-menu {
    &__btn {
        display: none;
    }
}

.pd {
    padding: 20px !important;
}


.pt-lr {
    position: relative;
    top: -120px;
}

.mt-md {
    margin-top: 40px !important;
}

.mt-lr {
    margin-top: 90px;
}

.border-none {
    border-left: 0 !important;
}

.img-right {
    position: absolute;
    top: 0;
    right: 0 !important;
    left: inherit !important;
    width: 50%;
    height: 100%;
    background-color: #2D5A6E;
    mix-blend-mode: multiply;
    opacity: 0.6;
}
.txt-right {
    right: 8%;
    left: unset !important;
    width: 400px;
}

.main-content {
    position: relative;
    z-index: 1;
}



.header {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    z-index: 100; 
    background:#ffffff;

    &__nav {
      display: block;
      position: relative;
    }   

    &__tel {
        font-size: 26px;
        position: absolute;
        top: 10px;
        right: 30%;
       
        & > a {
            color: #2d5a6e;
        }
    }
    &__ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style: none;

      &-wrapper {
          padding-top: 40px;
          display: flex;
          flex-wrap: row;
      }
	 
    }   
    &__li {
        margin-left: 20px;
        border-left: 1px solid black;
        padding-left: 20px;

      &:first-child {
          border-left: 0;
      }
      &:nth-child(5) {
          border-left: 0;
      }
	 
      & > a {
        font-size: 16px;
        display: block;
        color: #64645A;
        text-decoration: none !important;
        text-transform: uppercase;
       

        & > span {
            position: relative;
            top: 20px;
            left: 5px;
            font-size: 16px;

        }
        & > img {
            position: relative;
            top: 25px;
            left: -4px;
        }
      }
    }   
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
	  margin-left: 60px;

       & > a {
           display: flex;
           flex-direction: row;
           align-items: center;

            &:hover {
                color:#64645A;
                text-decoration: none !important;            }
        }
    }
}

.logo {
    &__title {
        color:#64645A;
        font-size: 12px;
        letter-spacing: 0.5px;
        padding-left: 3px;
        padding-bottom: 4px;
    }

    &__right {
        padding-left: 30px;
    }
}

.hero {
    padding-top: 80px;
    display: flex;
    position: relative;
    &__image {
        &-left {
            background-color: #ffffff;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 40%;

            &::after {
                content: '';
                display: block;
                padding-top: 65%;
            }

        }
        &-right {
            background-image: url(../images/hero_image.png);
            background-position: 20%;
            background-repeat: no-repeat;
            background-size: cover;
            width: 60%;
    
            &::after {
                content: '';
                display: block;
                padding-top: 65%;
            }

        }
    }

    &__title {
        font-size: 37px;
        position: absolute;
        top: 50%;
        left: 15%;
        transform: translateY(-50%);
    }
}

.info {

    &__inner {
        background-color: #f0f0f0;
        width: 70%;
        margin:0 auto;
        max-width: 1200px;
        height: 500px;
        position: relative;
        bottom: 40px;
        z-index: 1;
    }

    &__images {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 110px;
        margin: 0 70px;
    }

    &__panel {
        width: 250px;
        height: 250px;
        margin-left: 110px;
        &:first-child {
            margin-left: 0;
        }
    }

    &__txt {
        margin-top: 20px;
    }
    &__date {
        color: #2D5A6E;
        font-size: 14px;
    }
    &__ttl {
        font-size: 14px;
    }
    &__ttl2 {
        font-size: 14px;
    }
}

// カテゴリー
.category {
    &__inner {
        width: 96%;
        margin: 0 auto;
        max-width: 1300px;
    }

    &__panel {
        position: relative;
        top: 10px;
        display: inline-block;
        margin: 20px;
    }

    
    &__txt {
        position: absolute;
        top: 50%;
        left: 18%;
        z-index: 2;
        color: #ffffff;
        text-align: center;
        transform: translate(0, -50%);

    }

    &__cover {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        mix-blend-mode: multiply;
        background: #2D5A6E;
        opacity: 0.6;
        text-align: center;
    }

    &__parallax {
        background-image: url(../images/parallax.png);
        background-position: 70% 10%;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        width: 100%;
        margin-bottom: 40px;
        margin-top: 120px;
      
        &::after {
            content: '';
            display: block;
            padding-top: 25%;
        }
    }

    .is-ios & {
        &__parallax {
            background-attachment: scroll;
       }
    }

    &__ttl {
        color: #ffffff;
        font-size: 30px;
        position: relative;
        bottom: 10px;
       
    }
}

.service {

    &__inner {
        width: 80%;
        margin:0 auto;
        max-width: 1200px;
        position: relative;
        bottom: 40px;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f0;
        height: 300px;
        margin-top: 30px;
    }

    &__txt {
        width: 50%;
        font-size: 13px;

        & > p {
            margin-top: 25px;
            letter-spacing: 1.5px;
            line-height: 1.7;

        }
    }
    

    &__images {
        width: 40%;
        text-align: right;

        & > img {
            width: 45%;
        }
    }
}


.store {
    overflow: hidden;
    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-around;
        width: 50%;
        margin-left: 300px;
        margin-right: 500px;
        padding-top: 60px;
        
    }

    &__images {
       width: 40%;
    }
    &__des {
        width:60%;
        padding-left: 30px;

        & > img {
            margin-bottom: 20px;
        }
    }
    &__map {
        margin-top: 60px;
    }
    &__ttl {
        font-size: 14px;
        color: #2D5A6E;
        padding: 3px;
    }
    &__content {
        font-size: 14px;
        padding: 3px;
    }
    &__info {
        width: 600px;
    }
}

.icon {
    width: 70%;
    margin: 40px auto;
    max-width: 1200px;
    &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    &__left, &__right {
        border: 2px solid #2D5A6E;
        width: 400px;
       
    }

   &__left {
       text-align: center;
       position: relative;
       padding: 10px 20px;
       margin-left: 50px;

       & > img {
           display: block;
           position: absolute;
           top: 18px;
           left: 35px;
       }
   }

   &__right {
        margin-left: 30px;
        position: relative;
        padding: 10px 20px;

        & > img {
            display: block;
            position: absolute;
            top: 18px;
            left: 30px;
        }
   }

   &__txt {
    //    padding-left: 10px;
       & > span {
           font-size: 26px;
           color: #2D5A6E;
          
       }

   }
   &__p {
       font-size: 14px;
       color: #2D5A6E;
       margin: 0;
   }
   &__span {
       position: relative;
       top: 10px;
   }
}

.footer {
    height: 123px;
    width: 100%;
    background-color: #464646;
    position: relative;

    &__adress {
        font-size: 14px;
    }
    &__tel {
        font-size: 14px;
    }

    &__scroll {
        width: 55px;
        height: 55px;
        background-color: #2D5A6E;
        position: fixed;
        bottom: 122px;
        right: 0;
        z-index: 5;
        color: white;
        line-height: 55px;

        & > img {
            position: relative;
            bottom: 32px;
            left: 21px;
           
        }

        &-span {
            position: relative;
            left: 0px;
            top: 8px;
           
        }

        &-res {
            display: none;

            &-span {
                display: none;
            }
            & > img {
                display: none;
            }
        }

    }
    &__scroll-02 {
        width: 55px;
        height: 55px;
        background-color: #2D5A6E;
        position: fixed;
        bottom: 122px;
        right: 0;
        z-index: 5;
        color: white;
        line-height: 55px;

        & > img {
            position: relative;
            bottom: 32px;
            left: 21px;
          
        }

        &-span {
            position: relative;
            left: 0px;
            top: 8px;
        }
        &-res {
            display: none;

            &-span {
                display: none;
            }
            & > img {
                display: none;
            }
        }

    }

    &__wrapper {
        padding-top: 10px;
        width: 80%;
        margin:0 auto;
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &__tel {
        & > a {
            color: #ffffff;
        }
    }

    &__left {
        width: 50%;
        text-align: right;
        margin-right: 50px;
    }
    
    &__right {
        color: #ffffff;
        width: 50%;
    }

    &__copyright {
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -10%);
    }
}



/*====================================================================
.s_05 .accordion_one
====================================================================*/
.s_05 .accordion_one {
    margin: 30px auto;
  }

  .accordion_header {
    height: 220px;
    width: 100%;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    position: relative;
    z-index: +1;
    cursor: pointer;
    overflow: hidden;
  }
  
  .accordion_header > img {
    width: 100%;
    height: 100%;
    transition-duration: 0.5s;
    object-fit: cover;
  }
  .accordion_header > img:hover {
    transition-duration: 0.5s;
    transform: scale(1.05);
  }
  
  .accordion_cover {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(45, 90, 110, 0.6);
    mix-blend-mode: multiply;
  }
  
  .accordion_one:nth-child(2n) .accordion_header > .accordion_cover {
    right: 0;
    left: auto;
  }
  
  .accordion_title-wrapper {
    position: absolute;
    top: 30%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
  
  .accordion_one:nth-child(2n) .accordion_header > .accordion_title-wrapper {
    top: 30%;
    right: 25%;
    left: auto;
    transform: translate(50%, -50%);
  }
  
  .accordion_jatitle {
    font-size: 26px;
    font-family: 'Sawarabi Gothic', sans-serif;
    font-weight: normal;
  }
  .accordion_entitle {
    font-size: 38px;
    font-family: 'ZCOOL XiaoWei', serif;
    font-weight: normal;
  }
  
  .accordion_btn {
    border: 1px solid white;
    width: 210px;
    height: 40px;
    background: rgba(100, 100, 90, 0.5);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 30px auto -80px;
    border: 1px solid white;
    position: relative;
  }
  .accordion_btn-ttl {
      position: relative;
      bottom: 1px;
  }
  .accordion_btn-ttl::before {
    content: "See detail";
    font-size: 16px;
    letter-spacing: 1.5px;
    font-style: inherit;
    font-family: "Sawarabi Gothic";
    font-weight: normal;
    line-height: 40px;
  }
  .accordion_header.open .accordion_btn-ttl::before {
    display: none;
  }
  
  .accordion_btn-ttl::after {
    content: "Close";
    display: none;
    font-size: 16px;
    letter-spacing: 1.5px;
    font-style: inherit;
    font-family: "Sawarabi Gothic";
    font-weight: normal;
    line-height: 40px;
  }
  
  .accordion_header.open .accordion_btn-ttl::after {
    display: block;
  }
  
  .one_i {
    display: block;
    width: 18px;
    height: 18px;
    transform: rotate(225deg);
    transform-origin: center center;
    transition-duration: 0.2s;
    position: relative;
    left: 156px;
    top: -28px;
  }
  .accordion_header.open .i_box {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  .one_i:before,
  .one_i:after {
    display: flex;
    content: "";
    background-color: #fff;
    border-radius: 10px;
    width: 12px;
    height: 2px;
    position: absolute;
    top: 10px;
    left: 2px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transform-origin: center center;
  }
  .one_i:before {
    width: 2px;
    height: 12px;
    left: 2px;
  }
  .accordion_header.open .i_box .one_i:before {
    transform: rotate(-90deg);
  }
  .accordion_header.open .i_box .one_i:after {
    transform: rotate(-90deg);
  }
  .accordion_inner {
    padding: 50px 30px 0;
    height: 610px;
    background: #f0f0f0;
    display: none;
    box-sizing: border-box;
  }
  

  .s_05 .accordion_one .accordion_inner p.txt_a_ac {
    margin: 0;
  }
  .s_05 .accordion_one .accordion_inner .closeArea {
    width: 210px;
    margin: 0 auto;
  }
  .s_05 .accordion_one .accordion_inner .closeArea .close_box a.close_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #64645a;
    opacity: 0.3;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    transition-duration: 0.2s;
    width: 210px;
    height: 45px;
    font-style: inherit;
    font-family: "Sawarabi Gothic";
    font-weight: normal;
    line-height: 45px;
    letter-spacing: 1.5px;
  }
  .s_05 .accordion_one .accordion_inner .closeArea .close_box a.close_btn .i_box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 17px;
    right: 10px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
  }
  .s_05
    .accordion_one
    .accordion_inner
    .closeArea
    .close_box
    a.close_btn
    .i_box
    .one_i {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -10px;
    top: 10px;
    width: 18px;
    height: 18px;
  }
  .s_05
    .accordion_one
    .accordion_inner
    .closeArea
    .close_box
    a.close_btn
    .i_box
    .one_i:before,
  .s_05
    .accordion_one
    .accordion_inner
    .closeArea
    .close_box
    a.close_btn
    .i_box
    .one_i:after {
    content: "";
    background-color: #fff;
    border-radius: 10px;
    width: 12px;
    height: 2px;
    position: absolute;
    top: 3px;
    left: 10px;
    transform: rotate(90deg);
    transform-origin: center center;
  }
  .s_05
    .accordion_one
    .accordion_inner
    .closeArea
    .close_box
    a.close_btn
    .i_box
    .one_i:before {
      width: 2px;
      height: 12px;
      top: 3px;
  }

.box_one {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.box_one:nth-child(2) {
    flex-direction: row-reverse;
    margin: 50px 0;
}
.box_one-images {
    width: 40%;
    display: flex;
    flex-direction: row;
}

.box_one-image01 {
    & > img {
        // height: 180px;
        object-fit: cover;
    }
}
.box_one-image02 {
    & > img {
        margin-left: 5%;
        // height: 180px;
        object-fit: cover;
    }
}
.box_text {
    width: 37%;
    margin-left: 50px;
    letter-spacing: 1.5px;
    line-height: 1.6;
    font-size: 13px;
}
.box_one:nth-child(2) .box_text {
    margin-left: 0;
    margin-right: 40px;
}

.top {
    &__news {
        background-image: url(../images/top_img.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        position: relative;

        &::after {
            content: '';
            display: block;
            padding-top: 20%;
        }
        &-title {
            position: absolute;
            top: 60%;
            left: 50%;
            color: white;
            transform: translate(-50%, -50%);
        }

        &-maintitle {
            font-size: 40px;
        }
        &-subtitle {
            font-size: 20px;
            text-align: center;
        }

    }
    &__des {
        margin-left: 150px;
        margin-top: 30px;

        &-home {
            & > a {
                color: #64645A;
            }
        }

        & > span {
            margin-right: 20px;
            color: #64645A;
        }

        &-ichiran {
            margin-right:20px;
            color: #64645A;
        }
    }
    &__des02 {
        margin-left: 150px;
        margin-top: 30px;

        &-home {
            & > a {
                color: #64645A;
            }
        }

        & > span {
            margin-right: 20px;
            color: #64645A;
        }

        &-ichiran {
            margin-right:20px;
            color: #64645A;
        }
    }
}

.main {
    &__inner {
        width: 80%;
        margin: 0 auto;
        max-width: 1200px;
    }

    &__panel {
       margin: 20px auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #f0f0f0;
        height: 140px;
        width: 90%;

        &:first-child {
            margin-top: 70px;
        }
    }

    &__images {
        width: 20%;

        & > img {
            height: 140px;
        }
    }

    &__date {
        color: #2D5A6E;
    }
    &__txt {
        width: 100%;

        &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
        }
    }
    &__des {
        width: 40%;
        padding-top: 90px;
        font-size: 12px;
        color: #2D5A6E;
        margin-left: 140px;
        margin-right: 20px;
       
        &-yajirushi {
             position: relative;
             left: 10px;
             font-size: 16px;
        }
    }
}

// ページネーション
.pager {
    &__span {
        color: #2D5A6E;
    }
}
.pager .pagination {
    text-align: center;
    padding: 90px;
    margin: 0;
  }
  
  .pager .pagination li {
    display: inline;
    margin: 0 2px;
    padding: 0;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    position: relative;
    border-radius: 10px;
    font-size: 22px;
    
  }

// news詳細ページ
.main-detaile {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;

    &__images {
        padding-top: 80px;
    }

    &__title {

        &-wrapper {
            padding-top: 20px;
        }
        color: #464646;
    }

    &__date {
        &-wrapper {
            padding-top: 20px;

        }
        color: #2D5A6E;
    }

    &__txt {
        color: #464646;
        &-wrapper {
            text-align: left;
            margin: 50px 0 30px 0;
        }
    }

    &__images {
        &-wrapper {
            padding-top: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__images01 {
        width:calc(50% - 20px);
        & > img {
            width: 100%;
        }
    }
    &__images02 {
        width: calc(50% - 20px);
        & > img {
            width: 100%;
        }
    }

    &__btn {
        margin: 120px 0;

        & > a {
            text-decoration: none;
            color: #2D5A6E;
        }
    }
}