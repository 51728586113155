@media (max-width:480px) {

    .section {
        &__title {
            &-wrapper {
                &-category {
                    height: 100px;
                }
            }

            &-left {
                &-category {
                    display: block;

                    & > img {
                        position: relative;
                        top: 20px;
                        right: 35px;
                    }
                }
            }
        }
        &__jattl {
            &-category {
                display: block;
                position: relative;
                bottom: 30px;
            }
        }
        &__enttl {
            &-category {
                display: block;
                top: -30px;
                left: 20px;
               
            }
        }
    }

    .mobile-menu {
        &__span1 {
            font-size: 25px;
        }
        &__span2 {
            font-size: 16px;
        }
        &__li {
            height: 70px;
            & > a {
                width: 60%;
                text-align: left;
                margin: 0 auto;
            }
        }
    }
    .logo {
        &__img {
            width: 80%;
        }
    }
    .header {
        &__inner {
            margin-left: 20px;
        }
    }
    .hero {
        &__image {
            &-left {
                &::after {
                    padding-top: 115%;
                }
            }
            &-right {
                &::after {
                    padding-top: 115%;
                }
            }
        }
    }

    .category {
        &__parallax {
            margin-top: 40px;
            margin-bottom: 20px;
          
            &::after {
                padding-top: 55%;
            }
        }
    }

    .store {
        &__info {
            width: 80%;
            margin: 0 auto;

            & > table {
                position: relative;
                bottom: 30px;
            }
        }

        &__des {
            top: 30px;
        }

        &__ttl {
            display: block;
            color: #2d5a6e;
        }
        &__content {
            display: block;
            padding-bottom: 10px;
        }
    }

    .service {
        &__wrapper {
            height: 450px;
        }
    }

    .icon {
        &__txt {
            & > span {
                font-size: 20px;
                position: relative;
                left: 20px;
            }
        }
        &__p {
            position: relative;
            left: 20px;
        }

        &__left {
            & > img {
                left: 25px;
            }
        }
    }
    .footer {
        &__left {
            // width: 100%;
        }
    }

    .main {
        &__inner {
            width: 100%;
        }
        &__txt {
            width: 80%;
        }
        &__images {
            width: 80%;

            & > img {
                height: 180px;
            }
        }

        &__panel {
            height: 180px;

            &:first-child {
                margin-top: 30px;
            }
        }
    }

    .pager .pagination {
        padding: 30px;
    }

    .top {
        &__news {
            &::after {
                padding-top: 70%;
            }
        }

        &__des {
            margin-left: 50px;
            margin-top: 20px;
        }
    }

    .accordion {
        &__title {
            width: 80%;
        }
        &__jattl {
            font-size: 22px;
        }
        &__enttl {
            font-size: 33px;
        }
    }
}

@media (max-width:440px) {
    .mobile-menu {

        &__li {
           
            & > a {
                width: 70%;
               
            }
        }
    }
    .section {
       
        &__enttl {
            margin-left:5px;
            font-size: 15px;
        }
        &__jattl {
            margin-left: 10px;
            font-size: 33px;

            &-category {
                font-size: 33px;
            }
        }
    }
}

@media (max-width:390px) {
    .section {
        &__title {
            &-wrapper {
                padding: 0 70px;
            }
        }
    }
}