@media (max-width:780px) {
    .mb-md {
        margin: 0;
    }
    .pt {
        padding-top: 50px;
    }
    .pb {
        padding-bottom: 130px;
    }

    .po-right-mn {
        right: 0px;
        position: relative;
    }

    .po-right-md {
        right: 0px;
        position: relative;
    }
    .po-right-lr {
        right: 0px;
        position: relative;
    }

    .po-left-md {
        position: relative;
        left: 0px;
    }
    .po-left-lr {
        position: relative;
        left: 0px;
    }

   
    .hero__title {
        font-size: 20px;
        top: 60%;
    }

    .slick-slide img {
        width: 80%;
        margin: 0 auto;
    }

    .btn {
        &__ichiran {
            display: none;
        }
    }

    .section {
        display: block;
        position: relative;
    }

    .section {
        &__title {
            &-wrapper {
                width: 100%;
                
                &-category {
                    width: 100%;
                }
            }


            &-left {
               
                margin: 0 auto;

                &-category {
                    margin: 0 auto;
                }
            }
        }
        &__enttl {
            font-size: 13px;

            &-category {
                margin-left: 20px;
            }
        }
    }

    .info {
        &__inner {
            display: none;
            overflow-x: auto;
            overflow-y: visible;
            width: 100%;
        }
        &__images {
            display: inline-flex;
            flex-wrap: nowrap;
        }
        &__txt {
            margin-left: 30px;
        }
    }

    
    .txt-right {
       right: 0;
       left: unset;
    }

    .s_05 .accordion_one {
        width: 100%;
    }

    .accordion_inner {
        height:730px;
    }

    .slide-down {
        position: relative;
        top: 20px;
        left: 35px;

        &-right {
            position: relative;
            top: 20px;
            left: 35px;
        }
    }

    .slide-down__item {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        text-align: center;

        &-right {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            text-align: center;
        }
    }

    .content-row {
        flex-direction: column;
        width: 100%;

        &:nth-child(2) {
            flex-direction: column;
        }
    }
    .slide-2 {
        &__txt {
            width: 70%;
        }

        &__images {
            width: 100%;
            justify-content: center;
        }
       
    }

    .toggleButton {
        transform: translate(-50%,-50%);
        position: absolute;
        top: 0;
        right: 50%;
        left: 50%;
        margin-top: 160px;
        width: 40%;

    }

    .toggleButton-top{
        width: 50%;
        left: 50%;
        right: inherit;
        transform: translate(-50%);
        margin: 30px auto 0;
    
        &-left {
            text-align: center;
            width: 50%;
            margin: 30px auto 0;
            position: absolute;
            top: 10px;
            left: 50%;
            z-index: 2;
            font-size: 30px;
            color: #ffffff;
            transform: translate(-50%);
        }
    }

    .toggleButton-under{
        width: 50%;
        left: 50%;
        right: inherit;
        transform: translate(-50%);
        margin: 30px auto 0;

        &-left {
            text-align: center;
            width: 50%;
            margin: 30px auto 0;
            position: absolute;
            top: 50px;
            left: 50%;
            z-index: 2;
            font-size: 30px;
            color: #ffffff;
            transform: translate(-50%);
        }
    }


    .service {
        &__inner {
            width: 100%;
        }
        &__wrapper {
            flex-direction: column-reverse;
            height: 500px;
        }
        &__images {
            width: 100%;
            text-align: center;
        }
        &__txt {
            width: 90%;
            margin-top: 13px;
        }
    }

   .store {
       &__wrapper {
        width: 95%;
        /* margin: 0 auto; */
        justify-content: center;
        margin-left: auto;
       }

       &__images {
           width: 90%;
       }

   }

   .footer {
       height: 183px;
       &__wrapper {
           flex-direction: column;
           text-align: center;
       }

       &__scroll {
        bottom: 182px;
        
        &-02 {
            bottom: 182px;
        }
    }

       
       &__right {
           width: 100%;
           position: relative;
           top: 25px;
       }
       &__left {
           width: 350px;
           text-align: center;
           position: relative;
           top: 10px;
           margin-right: 0;
       }
   }
   .footer {

    &__scroll {
        display: none !important;
        &-res {
            width: 55px;
            height: 55px;
            background-color: #2D5A6E;
            position: fixed;
            bottom: 182px;
            right: 0;
            z-index: 5;
            color: white;
            line-height: 55px;

            & > img {
                position: relative;
                bottom: -12px;
                left: 21px;
                display: block;
            }
    
            &-span {
                position: relative;
                left: 13px;
                top: 0px;
                display: block;
            }

            &-res {
                display: block;
    
                &-span {
                    display: block;
                }
                & > img {
                    display: block;
                }
            }
        }
    }
    &__scroll-02 {
        display: none !important;
        &-res {
            width: 55px;
            height: 55px;
            background-color: #2D5A6E;
            position: fixed;
            bottom: 122px;
            right: 0;
            z-index: 5;
            color: white;
            line-height: 55px;

            & > img {
                position: relative;
                bottom: -12px;
                left: 21px;
                display: block;
              
            }
    
            &-span {
                position: relative;
                left: 13px;
                top: 0px;
                display: block;
            }

            &-res {
                display: block;
    
                &-span {
                    display: block;
                }
                & > img {
                    display: block;
                }
            }
        }
    }
  }

   .main {
       &__txt {
        margin: 0 auto;
            &-wrapper {
                flex-direction: column;
            }
        }
        
        &__text {
            
            & > p {
                margin: 0;
            }
       }

       &__des {
           padding-top: 20px;
           width: 100%;
           text-align: center;
           margin-left: 0;
       }
   }

   .top {
       &__des {
           text-align: center;
           margin-left: 0;
          
       }
       &__des02 {
           text-align: center;
           margin-left: 0;
       }
   }
   .main-detaile {
       &__images {
           padding-top: 50px;
       }
       
   }
}

@media(max-width:720px) {
     .box_one {
        flex-direction: column;
    }
    .box_one:nth-child(2) {
        flex-direction: column;
    }
    .box_one-images {
        width: 100%;
        justify-content: center;
    }
    .box_one-image01 {
        & > img {
            margin-left: -1%;
        }
    }
    .box_one-image02 {
        & > img {
            margin-left: 3%;
        }
    }
    .box_text {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }
    .box_one:nth-child(2) .box_text {
        margin-right: 0;
        margin-top: 30px;
    }
    .accordion_inner {
        height: 900px;
    }
    .content-row {
        flex-direction: column;
        width: 100%;

        &:nth-child(2) {
            flex-direction: column;
        }
    }
    .slide-2 {
        &__txt {
            width: 100%;
        }

        &__images {
            width: 100%;
            justify-content: center;
        }
    }
}

@media (max-width:790px) {
    .btn__ichiran {
        padding: 10px 40px;
    }
}



@media (max-width:890px) {
    .section {
        &__title {
            &-wrapper {
                padding: 0 60px;
            }
        }
        &__enttl {
            margin-left: 10px;
        }
    }
}