$pattern: (
  "up": translateY(6px),
  "down": translateY(-6px),
  "left": translateX(40px),
  "right": translateX(-40px)
);

@each $key, $value in $pattern {
  .appear.#{$key} {
    & .item {
      transform: $value;
    }
  }
}

.appear {
  & .item {
    transition: all 0.8s;
    opacity: 0;
  }
  &.inview {
    & .item {
      opacity: 1;
      transform: none;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.1s;
        }
      }
    }
  }
}