.back-color {
    background-color: #2d5a6e;
    border: 1px solid #ffffff;
    text-align: center !important;
}

.fo-color {
    color: #ffffff !important;
}

.mb-mi {
    margin-bottom: 20px;
}
.mobile-menu {
    position: fixed;
    right: -60px;
    top: 60px;
    width: 300px;

    &__nav {
        padding-top: 80px;
    }
    &__ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;
        margin: 0 auto;
    }

    &__li {
        list-style: none;
        width: 100%;
        height: 90px;
        text-align: left;
        position: relative;

        & > a {
            text-decoration: none;
            color: #534741;
            width: 80%;
            margin-left: auto;
            margin-right: 0;
        }

        &-tel {
            height: 90px;
            list-style: none;
        }
        &-mail {
            height: 90px;
            list-style: none;
        }

        &:after {
            content: '';
          
            background-position: center;
            background-size: contain;
            background-repeat: repeat;
            display: inline-block;
            width: 90%;
            height: 2px;
            vertical-align: middle;
        }

    }
    &__link {
        display: block;
        height: 60px;
        line-height: 60px;
        color: #534741;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
       
        & > .fab {
            font-size: 32px;
        }

        &-tel {
            width: 100%;
            height: 100%;
            display: block;
        }

        &-mail {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &__image {
        &-tel {
            width: 50px;
            margin-right: 30px;
            position: relative;
            top: 23px;
        }
        &-mail {
            width: 50px;
            margin-right: 30px;
            position: relative;
            top: 23px;
            right: 57px;
        }
    }

    &__span {
        &-tel {
            font-size: 25px;
            position: relative;
            top: 20px;
        }
    }

    &__span1 {
        color: #2d5a6e;
        font-size: 30px;
        padding: 12px;
        position: relative;
        top: 15px;
        font-weight: normal;
    }
    &__span2 {
        color: #2d5a6e;
        font-size: 20px;
        position: relative;
        top: 15px;
        font-weight: normal;
    }

    &__h1 {
         & > a:hover {
             color: #323232;
             text-decoration: none;
         }
    }

    &__btn {
        background-color: unset;
        border: none;
        outline: none !important;
        cursor: pointer;
        // display: none;

        & > span {
            background-color: #ffffff;
            width: 35px;
            height: 2px;
            display: block;
            margin-bottom: 9px;
            margin-left: 17px;
            transition: transform .7s;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__cover {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 99;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
        background: rgba(255, 255, 255, .9);
        transition: all 0.8s;
        transform: translateY(-100%);
       
    }
    &__main {
        padding: 0;
        perspective: 2000px;
        transform-style: preserve-3d;
    }
    &__item {
        list-style: none;
        display: block;
        transform: translate3d(0, 0, -1000px);
        padding: 0 40px;
        transition: transform 0.3s, opacity 0.2s;
        opacity: 0;
    }

    &__h1 {
        margin: 30px auto 20px;
        line-height: 50px;
        text-align: center;

        & > img {
            width: 100px;
            height: 50px;
        }

    }
    
    &__about {
        font-size: 20px;
        font-weight: normal;
    }
    
    &__text {
        width: 90%;
        margin: 0 auto;
    }
}

.menu-open {
    & .mobile-menu {
        &__cover {
            height:auto;
            transition: all 0.8s;
            visibility: visible;
            transform: none;
          
        }

        &__item {
            transform: none;
            opacity: 1;

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    transition-delay: $i * 0.07s;
                }
            }
        }

        &__btn {
            & > span {
                background-color:#ffffff;

                &:nth-child(1) {
                    transition-delay: 70ms;
                    transform: translateY(11px) rotate(135deg);
                }
                &:nth-child(2) {
                    transition-delay: 0s;
                    transform:  translateX(-18px) scaleX(0);
                }
                &:nth-child(3) {
                    transition-delay: 140ms;
                    transform: translateY(-11px) rotate(-135deg);
                }
            }
        }
    }
}
