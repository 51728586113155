@charset "UTF-8";
.appear.up .item {
  transform: translateY(6px); }

.appear.down .item {
  transform: translateY(-6px); }

.appear.left .item {
  transform: translateX(40px); }

.appear.right .item {
  transform: translateX(-40px); }

.appear .item {
  transition: all 0.8s;
  opacity: 0; }

.appear.inview .item {
  opacity: 1;
  transform: none; }
  .appear.inview .item:nth-child(1) {
    transition-delay: 0.1s; }
  .appear.inview .item:nth-child(2) {
    transition-delay: 0.2s; }
  .appear.inview .item:nth-child(3) {
    transition-delay: 0.3s; }
  .appear.inview .item:nth-child(4) {
    transition-delay: 0.4s; }
  .appear.inview .item:nth-child(5) {
    transition-delay: 0.5s; }
  .appear.inview .item:nth-child(6) {
    transition-delay: 0.6s; }
  .appear.inview .item:nth-child(7) {
    transition-delay: 0.7s; }
  .appear.inview .item:nth-child(8) {
    transition-delay: 0.8s; }
  .appear.inview .item:nth-child(9) {
    transition-delay: 0.9s; }
  .appear.inview .item:nth-child(10) {
    transition-delay: 1s; }

.btn.filled {
  width: 170px;
  height: 80px;
  background-color: #2D5A6E;
  text-align: center;
  color: #ffffff;
  font-size: 10px; }

.btn__ichiran {
  font-size: 14px;
  width: 200px;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 10px 50px;
  position: relative; }
  .btn__ichiran:hover {
    text-decoration: none;
    color: white; }
  .btn__ichiran-second {
    font-size: 14px;
    width: 200px;
    color: #2D5A6E;
    border: 1px solid #2D5A6E;
    padding: 10px 50px;
    position: relative; }
  .btn__ichiran-res {
    font-size: 14px;
    width: 200px;
    color: #2D5A6E;
    border: 1px solid #2D5A6E;
    padding: 7px 50px;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%); }
    .btn__ichiran-res:hover {
      text-decoration: none;
      color: #2D5A6E; }

.btn__detail {
  font-size: 14px;
  width: 200px;
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: #64645a;
  opacity: 0.6;
  padding: 8px 50px;
  position: relative;
  top: 17px; }
  .btn__detail::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0; }

.btn-yajirushi {
  position: relative;
  top: -4px;
  left: 20px; }

.animate-title,
.tween-animate-title {
  opacity: 0; }
  .animate-title.inview,
  .tween-animate-title.inview {
    opacity: 1; }
    .animate-title.inview .char,
    .tween-animate-title.inview .char {
      display: inline-block; }
  .animate-title .char,
  .tween-animate-title .char {
    opacity: 0; }

.animate-title.inview .char {
  animation-name: kf-animate-chars;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.39, 1.57, 0.58, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both; }
  .animate-title.inview .char:nth-child(1) {
    animation-delay: 0.04s; }
  .animate-title.inview .char:nth-child(2) {
    animation-delay: 0.08s; }
  .animate-title.inview .char:nth-child(3) {
    animation-delay: 0.12s; }
  .animate-title.inview .char:nth-child(4) {
    animation-delay: 0.16s; }
  .animate-title.inview .char:nth-child(5) {
    animation-delay: 0.2s; }
  .animate-title.inview .char:nth-child(6) {
    animation-delay: 0.24s; }
  .animate-title.inview .char:nth-child(7) {
    animation-delay: 0.28s; }
  .animate-title.inview .char:nth-child(8) {
    animation-delay: 0.32s; }
  .animate-title.inview .char:nth-child(9) {
    animation-delay: 0.36s; }
  .animate-title.inview .char:nth-child(10) {
    animation-delay: 0.4s; }
  .animate-title.inview .char:nth-child(11) {
    animation-delay: 0.44s; }
  .animate-title.inview .char:nth-child(12) {
    animation-delay: 0.48s; }
  .animate-title.inview .char:nth-child(13) {
    animation-delay: 0.52s; }
  .animate-title.inview .char:nth-child(14) {
    animation-delay: 0.56s; }
  .animate-title.inview .char:nth-child(15) {
    animation-delay: 0.6s; }
  .animate-title.inview .char:nth-child(16) {
    animation-delay: 0.64s; }
  .animate-title.inview .char:nth-child(17) {
    animation-delay: 0.68s; }
  .animate-title.inview .char:nth-child(18) {
    animation-delay: 0.72s; }
  .animate-title.inview .char:nth-child(19) {
    animation-delay: 0.76s; }
  .animate-title.inview .char:nth-child(20) {
    animation-delay: 0.8s; }
  .animate-title.inview .char:nth-child(21) {
    animation-delay: 0.84s; }
  .animate-title.inview .char:nth-child(22) {
    animation-delay: 0.88s; }
  .animate-title.inview .char:nth-child(23) {
    animation-delay: 0.92s; }
  .animate-title.inview .char:nth-child(24) {
    animation-delay: 0.96s; }
  .animate-title.inview .char:nth-child(25) {
    animation-delay: 1s; }
  .animate-title.inview .char:nth-child(26) {
    animation-delay: 1.04s; }
  .animate-title.inview .char:nth-child(27) {
    animation-delay: 1.08s; }
  .animate-title.inview .char:nth-child(28) {
    animation-delay: 1.12s; }
  .animate-title.inview .char:nth-child(29) {
    animation-delay: 1.16s; }
  .animate-title.inview .char:nth-child(30) {
    animation-delay: 1.2s; }

@keyframes kf-animate-chars {
  0% {
    opacity: 0;
    transform: translateY(-50%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.cover-slide {
  position: relative;
  overflow: hidden; }
  .cover-slide::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eaebe6;
    opacity: 0; }
  .cover-slide.inview::after {
    opacity: 1;
    animation-name: kf-cover-slide;
    animation-duration: 1.6s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both; }

@keyframes kf-cover-slide {
  0% {
    transform-origin: left;
    transform: scaleX(0); }
  50% {
    transform-origin: left;
    transform: scaleX(1); }
  50.1% {
    transform-origin: right;
    transform: scaleX(1); }
  100% {
    transform-origin: right;
    transform: scaleX(0); } }

.img-zoom, .bg-img-zoom {
  opacity: 0; }
  .inview .img-zoom, .inview .bg-img-zoom {
    opacity: 1;
    transition: transform 0.3s ease;
    animation-name: kf-img-show;
    animation-duration: 1.6s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none; }
    .inview .img-zoom:hover, .inview .bg-img-zoom:hover {
      transform: scale(1.05); }

@keyframes kf-img-show {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  50.1% {
    opacity: 1;
    transform: scale(1.5); }
  100% {
    opacity: 1; } }

.hover-darken::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: background-color 0.3s ease;
  pointer-events: none;
  animation-name: kf-img-show;
  animation-duration: 1.6s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none; }

.hover-darken:hover::before {
  background-color: rgba(0, 0, 0, 0.4); }

.bg-img-zoom {
  background-image: url(images/image-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%; }

.img-bg50 {
  position: relative; }
  .img-bg50::before {
    display: block;
    content: '';
    padding-top: 50%; }

.back-color {
  background-color: #2d5a6e;
  border: 1px solid #ffffff;
  text-align: center !important; }

.fo-color {
  color: #ffffff !important; }

.mb-mi {
  margin-bottom: 20px; }

.mobile-menu {
  position: fixed;
  right: -60px;
  top: 60px;
  width: 300px; }
  .mobile-menu__nav {
    padding-top: 80px; }
  .mobile-menu__ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    margin: 0 auto; }
  .mobile-menu__li {
    list-style: none;
    width: 100%;
    height: 90px;
    text-align: left;
    position: relative; }
    .mobile-menu__li > a {
      text-decoration: none;
      color: #534741;
      width: 80%;
      margin-left: auto;
      margin-right: 0; }
    .mobile-menu__li-tel {
      height: 90px;
      list-style: none; }
    .mobile-menu__li-mail {
      height: 90px;
      list-style: none; }
    .mobile-menu__li:after {
      content: '';
      background-position: center;
      background-size: contain;
      background-repeat: repeat;
      display: inline-block;
      width: 90%;
      height: 2px;
      vertical-align: middle; }
  .mobile-menu__link {
    display: block;
    height: 60px;
    line-height: 60px;
    color: #534741;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold; }
    .mobile-menu__link > .fab {
      font-size: 32px; }
    .mobile-menu__link-tel {
      width: 100%;
      height: 100%;
      display: block; }
    .mobile-menu__link-mail {
      width: 100%;
      height: 100%;
      display: block; }
  .mobile-menu__image-tel {
    width: 50px;
    margin-right: 30px;
    position: relative;
    top: 23px; }
  .mobile-menu__image-mail {
    width: 50px;
    margin-right: 30px;
    position: relative;
    top: 23px;
    right: 57px; }
  .mobile-menu__span-tel {
    font-size: 25px;
    position: relative;
    top: 20px; }
  .mobile-menu__span1 {
    color: #2d5a6e;
    font-size: 30px;
    padding: 12px;
    position: relative;
    top: 15px;
    font-weight: normal; }
  .mobile-menu__span2 {
    color: #2d5a6e;
    font-size: 20px;
    position: relative;
    top: 15px;
    font-weight: normal; }
  .mobile-menu__h1 > a:hover {
    color: #323232;
    text-decoration: none; }
  .mobile-menu__btn {
    background-color: unset;
    border: none;
    outline: none !important;
    cursor: pointer; }
    .mobile-menu__btn > span {
      background-color: #ffffff;
      width: 35px;
      height: 2px;
      display: block;
      margin-bottom: 9px;
      margin-left: 17px;
      transition: transform .7s; }
      .mobile-menu__btn > span:last-child {
        margin-bottom: 0; }
  .mobile-menu__cover {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.9);
    transition: all 0.8s;
    transform: translateY(-100%); }
  .mobile-menu__main {
    padding: 0;
    perspective: 2000px;
    transform-style: preserve-3d; }
  .mobile-menu__item {
    list-style: none;
    display: block;
    transform: translate3d(0, 0, -1000px);
    padding: 0 40px;
    transition: transform 0.3s, opacity 0.2s;
    opacity: 0; }
  .mobile-menu__h1 {
    margin: 30px auto 20px;
    line-height: 50px;
    text-align: center; }
    .mobile-menu__h1 > img {
      width: 100px;
      height: 50px; }
  .mobile-menu__about {
    font-size: 20px;
    font-weight: normal; }
  .mobile-menu__text {
    width: 90%;
    margin: 0 auto; }

.menu-open .mobile-menu__cover {
  height: auto;
  transition: all 0.8s;
  visibility: visible;
  transform: none; }

.menu-open .mobile-menu__item {
  transform: none;
  opacity: 1; }
  .menu-open .mobile-menu__item:nth-child(1) {
    transition-delay: 0.07s; }
  .menu-open .mobile-menu__item:nth-child(2) {
    transition-delay: 0.14s; }
  .menu-open .mobile-menu__item:nth-child(3) {
    transition-delay: 0.21s; }
  .menu-open .mobile-menu__item:nth-child(4) {
    transition-delay: 0.28s; }
  .menu-open .mobile-menu__item:nth-child(5) {
    transition-delay: 0.35s; }

.menu-open .mobile-menu__btn > span {
  background-color: #ffffff; }
  .menu-open .mobile-menu__btn > span:nth-child(1) {
    transition-delay: 70ms;
    transform: translateY(11px) rotate(135deg); }
  .menu-open .mobile-menu__btn > span:nth-child(2) {
    transition-delay: 0s;
    transform: translateX(-18px) scaleX(0); }
  .menu-open .mobile-menu__btn > span:nth-child(3) {
    transition-delay: 140ms;
    transform: translateY(-11px) rotate(-135deg); }

/*==================
slick
==================*/
.section {
  display: none; }

* {
  box-sizing: border-box; }

.sliderArea {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 25px; }

.sliderArea.w300 {
  max-width: 300px; }

.slick-slide {
  margin: 0 5px; }

.slick-slide img {
  width: 100%;
  height: auto; }

.slick-prev, .slick-next {
  z-index: 1; }

.slick-prev:before, .slick-next:before {
  color: #000; }

.slick-next:before {
  content: url(../images/slick-next.png);
  position: relative;
  bottom: 45px;
  right: 30px; }

.slick-prev:before {
  content: url(../images/slick-prev.png);
  position: relative;
  left: 30px;
  bottom: 43px; }

.slick-slide {
  transition: all ease-in-out .3s; }

.slick-dots li button {
  display: none; }

.thumb {
  margin: 20px 0 0; }

.thumb .slick-slide {
  cursor: pointer; }

.thumb .slick-slide:hover {
  opacity: .7; }

/*==================
  .full-screen
  ==================*/
.full-screen_one .slick-list {
  overflow: visible; }

.full-screen_one.slider {
  max-width: 300px;
  margin: 0 auto; }

/*==================
  以下は不要です。
  ==================*/
@media screen and (max-width: 1024px) {
  body {
    font-size: 14px; } }

.section {
  max-width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  overflow: hidden; }

.section p._a {
  font-size: 12px;
  font-weight: bold;
  margin: 30px 0 0; }

html {
  scroll-behavior: smooth; }

body {
  font-family: 'Sawarabi Gothic', sans-serif; }

*:focus {
  outline: none; }

.fo-zo {
  font-family: 'ZCOOL XiaoWei', serif; }

.fo-go {
  font-family: 'Sawarabi Gothic', sans-serif; }

img {
  max-width: 100%;
  vertical-align: bottom; }

a {
  color: black; }

a:hover {
  text-decoration: none; }

.mb-md {
  margin-bottom: 50px; }

.float-right {
  float: right; }

#info, #category, #service, #store {
  padding-top: 80px;
  margin-top: -80px; }

.section__title-wrapper {
  background-color: #2D5A6E;
  height: 80px;
  width: 70%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  position: relative;
  z-index: 2; }
  .section__title-wrapper-category {
    background-color: #2D5A6E;
    height: 80px;
    width: 70%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;
    position: relative;
    z-index: 2; }

.section__title-left {
  display: flex;
  align-items: center; }
  .section__title-left-category {
    display: flex;
    align-items: center; }

.section__jattl {
  font-family: 'ZCOOL XiaoWei', serif;
  font-size: 40px;
  color: #ffffff;
  margin-left: 20px; }
  .section__jattl-category {
    font-family: 'ZCOOL XiaoWei', serif;
    font-size: 40px;
    color: #ffffff;
    margin-left: 20px; }

.section__enttl {
  font-family: 'Sawarabi Gothic', sans-serif;
  font-size: 20px;
  color: #ffffff;
  margin-left: 40px;
  position: relative;
  top: 7px; }
  .section__enttl-category {
    font-family: 'Sawarabi Gothic', sans-serif;
    font-size: 14px;
    color: #ffffff;
    margin-left: 35px;
    position: relative;
    top: 7px; }

.mobile-menu__btn {
  display: none; }

.pd {
  padding: 20px !important; }

.pt-lr {
  position: relative;
  top: -120px; }

.mt-md {
  margin-top: 40px !important; }

.mt-lr {
  margin-top: 90px; }

.border-none {
  border-left: 0 !important; }

.img-right {
  position: absolute;
  top: 0;
  right: 0 !important;
  left: inherit !important;
  width: 50%;
  height: 100%;
  background-color: #2D5A6E;
  mix-blend-mode: multiply;
  opacity: 0.6; }

.txt-right {
  right: 8%;
  left: unset !important;
  width: 400px; }

.main-content {
  position: relative;
  z-index: 1; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  z-index: 100;
  background: #ffffff; }
  .header__nav {
    display: block;
    position: relative; }
  .header__tel {
    font-size: 26px;
    position: absolute;
    top: 10px;
    right: 30%; }
    .header__tel > a {
      color: #2d5a6e; }
  .header__ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none; }
    .header__ul-wrapper {
      padding-top: 40px;
      display: flex;
      flex-wrap: row; }
  .header__li {
    margin-left: 20px;
    border-left: 1px solid black;
    padding-left: 20px; }
    .header__li:first-child {
      border-left: 0; }
    .header__li:nth-child(5) {
      border-left: 0; }
    .header__li > a {
      font-size: 16px;
      display: block;
      color: #64645A;
      text-decoration: none !important;
      text-transform: uppercase; }
      .header__li > a > span {
        position: relative;
        top: 20px;
        left: 5px;
        font-size: 16px; }
      .header__li > a > img {
        position: relative;
        top: 25px;
        left: -4px; }
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 60px; }
    .header__inner > a {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .header__inner > a:hover {
        color: #64645A;
        text-decoration: none !important; }

.logo__title {
  color: #64645A;
  font-size: 12px;
  letter-spacing: 0.5px;
  padding-left: 3px;
  padding-bottom: 4px; }

.logo__right {
  padding-left: 30px; }

.hero {
  padding-top: 80px;
  display: flex;
  position: relative; }
  .hero__image-left {
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 40%; }
    .hero__image-left::after {
      content: '';
      display: block;
      padding-top: 65%; }
  .hero__image-right {
    background-image: url(../images/hero_image.png);
    background-position: 20%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 60%; }
    .hero__image-right::after {
      content: '';
      display: block;
      padding-top: 65%; }
  .hero__title {
    font-size: 37px;
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translateY(-50%); }

.info__inner {
  background-color: #f0f0f0;
  width: 70%;
  margin: 0 auto;
  max-width: 1200px;
  height: 500px;
  position: relative;
  bottom: 40px;
  z-index: 1; }

.info__images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 110px;
  margin: 0 70px; }

.info__panel {
  width: 250px;
  height: 250px;
  margin-left: 110px; }
  .info__panel:first-child {
    margin-left: 0; }

.info__txt {
  margin-top: 20px; }

.info__date {
  color: #2D5A6E;
  font-size: 14px; }

.info__ttl {
  font-size: 14px; }

.info__ttl2 {
  font-size: 14px; }

.category__inner {
  width: 96%;
  margin: 0 auto;
  max-width: 1300px; }

.category__panel {
  position: relative;
  top: 10px;
  display: inline-block;
  margin: 20px; }

.category__txt {
  position: absolute;
  top: 50%;
  left: 18%;
  z-index: 2;
  color: #ffffff;
  text-align: center;
  transform: translate(0, -50%); }

.category__cover {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
  background: #2D5A6E;
  opacity: 0.6;
  text-align: center; }

.category__parallax {
  background-image: url(../images/parallax.png);
  background-position: 70% 10%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 120px; }
  .category__parallax::after {
    content: '';
    display: block;
    padding-top: 25%; }

.is-ios .category__parallax {
  background-attachment: scroll; }

.category__ttl {
  color: #ffffff;
  font-size: 30px;
  position: relative;
  bottom: 10px; }

.service__inner {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  bottom: 40px; }

.service__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  height: 300px;
  margin-top: 30px; }

.service__txt {
  width: 50%;
  font-size: 13px; }
  .service__txt > p {
    margin-top: 25px;
    letter-spacing: 1.5px;
    line-height: 1.7; }

.service__images {
  width: 40%;
  text-align: right; }
  .service__images > img {
    width: 45%; }

.store {
  overflow: hidden; }
  .store__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    margin-left: 300px;
    margin-right: 500px;
    padding-top: 60px; }
  .store__images {
    width: 40%; }
  .store__des {
    width: 60%;
    padding-left: 30px; }
    .store__des > img {
      margin-bottom: 20px; }
  .store__map {
    margin-top: 60px; }
  .store__ttl {
    font-size: 14px;
    color: #2D5A6E;
    padding: 3px; }
  .store__content {
    font-size: 14px;
    padding: 3px; }
  .store__info {
    width: 600px; }

.icon {
  width: 70%;
  margin: 40px auto;
  max-width: 1200px; }
  .icon__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
  .icon__left, .icon__right {
    border: 2px solid #2D5A6E;
    width: 400px; }
  .icon__left {
    text-align: center;
    position: relative;
    padding: 10px 20px;
    margin-left: 50px; }
    .icon__left > img {
      display: block;
      position: absolute;
      top: 18px;
      left: 35px; }
  .icon__right {
    margin-left: 30px;
    position: relative;
    padding: 10px 20px; }
    .icon__right > img {
      display: block;
      position: absolute;
      top: 18px;
      left: 30px; }
  .icon__txt > span {
    font-size: 26px;
    color: #2D5A6E; }
  .icon__p {
    font-size: 14px;
    color: #2D5A6E;
    margin: 0; }
  .icon__span {
    position: relative;
    top: 10px; }

.footer {
  height: 123px;
  width: 100%;
  background-color: #464646;
  position: relative; }
  .footer__adress {
    font-size: 14px; }
  .footer__tel {
    font-size: 14px; }
  .footer__scroll {
    width: 55px;
    height: 55px;
    background-color: #2D5A6E;
    position: fixed;
    bottom: 122px;
    right: 0;
    z-index: 5;
    color: white;
    line-height: 55px; }
    .footer__scroll > img {
      position: relative;
      bottom: 32px;
      left: 21px; }
    .footer__scroll-span {
      position: relative;
      left: 0px;
      top: 8px; }
    .footer__scroll-res {
      display: none; }
      .footer__scroll-res-span {
        display: none; }
      .footer__scroll-res > img {
        display: none; }
  .footer__scroll-02 {
    width: 55px;
    height: 55px;
    background-color: #2D5A6E;
    position: fixed;
    bottom: 122px;
    right: 0;
    z-index: 5;
    color: white;
    line-height: 55px; }
    .footer__scroll-02 > img {
      position: relative;
      bottom: 32px;
      left: 21px; }
    .footer__scroll-02-span {
      position: relative;
      left: 0px;
      top: 8px; }
    .footer__scroll-02-res {
      display: none; }
      .footer__scroll-02-res-span {
        display: none; }
      .footer__scroll-02-res > img {
        display: none; }
  .footer__wrapper {
    padding-top: 10px;
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  .footer__tel > a {
    color: #ffffff; }
  .footer__left {
    width: 50%;
    text-align: right;
    margin-right: 50px; }
  .footer__right {
    color: #ffffff;
    width: 50%; }
  .footer__copyright {
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%); }

/*====================================================================
.s_05 .accordion_one
====================================================================*/
.s_05 .accordion_one {
  margin: 30px auto; }

.accordion_header {
  height: 220px;
  width: 100%;
  color: #fff;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  position: relative;
  z-index: +1;
  cursor: pointer;
  overflow: hidden; }

.accordion_header > img {
  width: 100%;
  height: 100%;
  transition-duration: 0.5s;
  object-fit: cover; }

.accordion_header > img:hover {
  transition-duration: 0.5s;
  transform: scale(1.05); }

.accordion_cover {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(45, 90, 110, 0.6);
  mix-blend-mode: multiply; }

.accordion_one:nth-child(2n) .accordion_header > .accordion_cover {
  right: 0;
  left: auto; }

.accordion_title-wrapper {
  position: absolute;
  top: 30%;
  left: 25%;
  transform: translate(-50%, -50%); }

.accordion_one:nth-child(2n) .accordion_header > .accordion_title-wrapper {
  top: 30%;
  right: 25%;
  left: auto;
  transform: translate(50%, -50%); }

.accordion_jatitle {
  font-size: 26px;
  font-family: 'Sawarabi Gothic', sans-serif;
  font-weight: normal; }

.accordion_entitle {
  font-size: 38px;
  font-family: 'ZCOOL XiaoWei', serif;
  font-weight: normal; }

.accordion_btn {
  border: 1px solid white;
  width: 210px;
  height: 40px;
  background: rgba(100, 100, 90, 0.5);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: 30px auto -80px;
  border: 1px solid white;
  position: relative; }

.accordion_btn-ttl {
  position: relative;
  bottom: 1px; }

.accordion_btn-ttl::before {
  content: "See detail";
  font-size: 16px;
  letter-spacing: 1.5px;
  font-style: inherit;
  font-family: "Sawarabi Gothic";
  font-weight: normal;
  line-height: 40px; }

.accordion_header.open .accordion_btn-ttl::before {
  display: none; }

.accordion_btn-ttl::after {
  content: "Close";
  display: none;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-style: inherit;
  font-family: "Sawarabi Gothic";
  font-weight: normal;
  line-height: 40px; }

.accordion_header.open .accordion_btn-ttl::after {
  display: block; }

.one_i {
  display: block;
  width: 18px;
  height: 18px;
  transform: rotate(225deg);
  transform-origin: center center;
  transition-duration: 0.2s;
  position: relative;
  left: 156px;
  top: -28px; }

.accordion_header.open .i_box {
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.one_i:before,
.one_i:after {
  display: flex;
  content: "";
  background-color: #fff;
  border-radius: 10px;
  width: 12px;
  height: 2px;
  position: absolute;
  top: 10px;
  left: 2px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transform-origin: center center; }

.one_i:before {
  width: 2px;
  height: 12px;
  left: 2px; }

.accordion_header.open .i_box .one_i:before {
  transform: rotate(-90deg); }

.accordion_header.open .i_box .one_i:after {
  transform: rotate(-90deg); }

.accordion_inner {
  padding: 50px 30px 0;
  height: 610px;
  background: #f0f0f0;
  display: none;
  box-sizing: border-box; }

.s_05 .accordion_one .accordion_inner p.txt_a_ac {
  margin: 0; }

.s_05 .accordion_one .accordion_inner .closeArea {
  width: 210px;
  margin: 0 auto; }

.s_05 .accordion_one .accordion_inner .closeArea .close_box a.close_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #64645a;
  opacity: 0.3;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  transition-duration: 0.2s;
  width: 210px;
  height: 45px;
  font-style: inherit;
  font-family: "Sawarabi Gothic";
  font-weight: normal;
  line-height: 45px;
  letter-spacing: 1.5px; }

.s_05 .accordion_one .accordion_inner .closeArea .close_box a.close_btn .i_box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 17px;
  right: 10px;
  width: 30px;
  height: 30px;
  margin-top: -15px; }

.s_05 .accordion_one .accordion_inner .closeArea .close_box a.close_btn .i_box .one_i {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -10px;
  top: 10px;
  width: 18px;
  height: 18px; }

.s_05 .accordion_one .accordion_inner .closeArea .close_box a.close_btn .i_box .one_i:before,
.s_05 .accordion_one .accordion_inner .closeArea .close_box a.close_btn .i_box .one_i:after {
  content: "";
  background-color: #fff;
  border-radius: 10px;
  width: 12px;
  height: 2px;
  position: absolute;
  top: 3px;
  left: 10px;
  transform: rotate(90deg);
  transform-origin: center center; }

.s_05 .accordion_one .accordion_inner .closeArea .close_box a.close_btn .i_box .one_i:before {
  width: 2px;
  height: 12px;
  top: 3px; }

.box_one {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.box_one:nth-child(2) {
  flex-direction: row-reverse;
  margin: 50px 0; }

.box_one-images {
  width: 40%;
  display: flex;
  flex-direction: row; }

.box_one-image01 > img {
  object-fit: cover; }

.box_one-image02 > img {
  margin-left: 5%;
  object-fit: cover; }

.box_text {
  width: 37%;
  margin-left: 50px;
  letter-spacing: 1.5px;
  line-height: 1.6;
  font-size: 13px; }

.box_one:nth-child(2) .box_text {
  margin-left: 0;
  margin-right: 40px; }

.top__news {
  background-image: url(../images/top_img.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative; }
  .top__news::after {
    content: '';
    display: block;
    padding-top: 20%; }
  .top__news-title {
    position: absolute;
    top: 60%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%); }
  .top__news-maintitle {
    font-size: 40px; }
  .top__news-subtitle {
    font-size: 20px;
    text-align: center; }

.top__des {
  margin-left: 150px;
  margin-top: 30px; }
  .top__des-home > a {
    color: #64645A; }
  .top__des > span {
    margin-right: 20px;
    color: #64645A; }
  .top__des-ichiran {
    margin-right: 20px;
    color: #64645A; }

.top__des02 {
  margin-left: 150px;
  margin-top: 30px; }
  .top__des02-home > a {
    color: #64645A; }
  .top__des02 > span {
    margin-right: 20px;
    color: #64645A; }
  .top__des02-ichiran {
    margin-right: 20px;
    color: #64645A; }

.main__inner {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px; }

.main__panel {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f0f0f0;
  height: 140px;
  width: 90%; }
  .main__panel:first-child {
    margin-top: 70px; }

.main__images {
  width: 20%; }
  .main__images > img {
    height: 140px; }

.main__date {
  color: #2D5A6E; }

.main__txt {
  width: 100%; }
  .main__txt-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row; }

.main__des {
  width: 40%;
  padding-top: 90px;
  font-size: 12px;
  color: #2D5A6E;
  margin-left: 140px;
  margin-right: 20px; }
  .main__des-yajirushi {
    position: relative;
    left: 10px;
    font-size: 16px; }

.pager__span {
  color: #2D5A6E; }

.pager .pagination {
  text-align: center;
  padding: 90px;
  margin: 0; }

.pager .pagination li {
  display: inline;
  margin: 0 2px;
  padding: 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
  border-radius: 10px;
  font-size: 22px; }

.main-detaile {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center; }
  .main-detaile__images {
    padding-top: 80px; }
  .main-detaile__title {
    color: #464646; }
    .main-detaile__title-wrapper {
      padding-top: 20px; }
  .main-detaile__date {
    color: #2D5A6E; }
    .main-detaile__date-wrapper {
      padding-top: 20px; }
  .main-detaile__txt {
    color: #464646; }
    .main-detaile__txt-wrapper {
      text-align: left;
      margin: 50px 0 30px 0; }
  .main-detaile__images-wrapper {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .main-detaile__images01 {
    width: calc(50% - 20px); }
    .main-detaile__images01 > img {
      width: 100%; }
  .main-detaile__images02 {
    width: calc(50% - 20px); }
    .main-detaile__images02 > img {
      width: 100%; }
  .main-detaile__btn {
    margin: 120px 0; }
    .main-detaile__btn > a {
      text-decoration: none;
      color: #2D5A6E; }

@media screen and (max-width: 1280px) {
  .accordion_inner {
    height: 720px; }
  .header {
    background: #ffffff; }
  .category__ttl {
    font-size: 20px; }
  .category__txt {
    top: 45%; }
  .info__panel {
    margin-left: 50px; }
  .info__inner {
    width: 80%; }
  .main__inner {
    width: 90%; }
  .main__panel {
    width: 100%; }
  .top__news::after {
    padding-top: 30%; } }

@media (max-width: 960px) {
  .header.triggered {
    background-color: #ffffff;
    mix-blend-mode: unset; }
  .header__nav {
    display: none; }
  .mobile-menu__btn {
    display: block;
    width: 80px;
    height: 80px;
    background-color: #2d5a6e; }
  .hero {
    padding-top: 80px; }
    .hero__title {
      font-size: 27px; }
  .info__panel {
    margin-left: 50px; }
  .category__inner {
    width: 100%; }
  .category__images {
    width: 100%; }
  .category__panel {
    margin: 12px 0;
    top: -20px; }
  .category__cover {
    width: 100%; }
  .category__txt {
    left: 50%;
    transform: translate(-50%, -50%); }
  .category__item > img {
    height: 200px;
    object-fit: cover; }
  .category__parallax {
    margin-top: 60px; }
    .category__parallax::after {
      content: '';
      display: block;
      padding-top: 35%; }
  .store__wrapper {
    flex-direction: column;
    width: 100%;
    padding-top: 30px;
    margin: 0 auto; }
  .store__images {
    width: 60%; }
    .store__images > img {
      width: 100%; }
  .store__des {
    padding-left: 0;
    width: 400px;
    position: relative;
    margin-top: 80px; }
    .store__des > img {
      position: absolute;
      top: -20%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .store__content {
    padding-left: 13px;
    padding-bottom: 10px; }
  .icon {
    width: 80%; }
    .icon__p {
      padding-left: 0; }
    .icon__txt {
      padding-left: 0; }
      .icon__txt > span {
        padding-left: 0; }
    .icon__wrapper {
      flex-direction: column;
      align-items: center; }
    .icon__left {
      width: 90%;
      margin-left: 0;
      margin-top: 30px; }
  .top__news::after {
    padding-top: 40%; }
  .accordion_cover {
    width: 100%; }
  .accordion__title {
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%); }
  .accordion_title-wrapper {
    left: 50%; }
  .accordion_one:nth-child(2n) .accordion_header > .accordion_title-wrapper {
    right: 50%; }
  .box_one-image01 > img {
    height: 180px; }
  .box_one-image02 > img {
    height: 180px; } }

@media (max-width: 780px) {
  .mb-md {
    margin: 0; }
  .pt {
    padding-top: 50px; }
  .pb {
    padding-bottom: 130px; }
  .po-right-mn {
    right: 0px;
    position: relative; }
  .po-right-md {
    right: 0px;
    position: relative; }
  .po-right-lr {
    right: 0px;
    position: relative; }
  .po-left-md {
    position: relative;
    left: 0px; }
  .po-left-lr {
    position: relative;
    left: 0px; }
  .hero__title {
    font-size: 20px;
    top: 60%; }
  .slick-slide img {
    width: 80%;
    margin: 0 auto; }
  .btn__ichiran {
    display: none; }
  .section {
    display: block;
    position: relative; }
  .section__title-wrapper {
    width: 100%; }
    .section__title-wrapper-category {
      width: 100%; }
  .section__title-left {
    margin: 0 auto; }
    .section__title-left-category {
      margin: 0 auto; }
  .section__enttl {
    font-size: 13px; }
    .section__enttl-category {
      margin-left: 20px; }
  .info__inner {
    display: none;
    overflow-x: auto;
    overflow-y: visible;
    width: 100%; }
  .info__images {
    display: inline-flex;
    flex-wrap: nowrap; }
  .info__txt {
    margin-left: 30px; }
  .txt-right {
    right: 0;
    left: unset; }
  .s_05 .accordion_one {
    width: 100%; }
  .accordion_inner {
    height: 730px; }
  .slide-down {
    position: relative;
    top: 20px;
    left: 35px; }
    .slide-down-right {
      position: relative;
      top: 20px;
      left: 35px; }
  .slide-down__item {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center; }
    .slide-down__item-right {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      text-align: center; }
  .content-row {
    flex-direction: column;
    width: 100%; }
    .content-row:nth-child(2) {
      flex-direction: column; }
  .slide-2__txt {
    width: 70%; }
  .slide-2__images {
    width: 100%;
    justify-content: center; }
  .toggleButton {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 0;
    right: 50%;
    left: 50%;
    margin-top: 160px;
    width: 40%; }
  .toggleButton-top {
    width: 50%;
    left: 50%;
    right: inherit;
    transform: translate(-50%);
    margin: 30px auto 0; }
    .toggleButton-top-left {
      text-align: center;
      width: 50%;
      margin: 30px auto 0;
      position: absolute;
      top: 10px;
      left: 50%;
      z-index: 2;
      font-size: 30px;
      color: #ffffff;
      transform: translate(-50%); }
  .toggleButton-under {
    width: 50%;
    left: 50%;
    right: inherit;
    transform: translate(-50%);
    margin: 30px auto 0; }
    .toggleButton-under-left {
      text-align: center;
      width: 50%;
      margin: 30px auto 0;
      position: absolute;
      top: 50px;
      left: 50%;
      z-index: 2;
      font-size: 30px;
      color: #ffffff;
      transform: translate(-50%); }
  .service__inner {
    width: 100%; }
  .service__wrapper {
    flex-direction: column-reverse;
    height: 500px; }
  .service__images {
    width: 100%;
    text-align: center; }
  .service__txt {
    width: 90%;
    margin-top: 13px; }
  .store__wrapper {
    width: 95%;
    /* margin: 0 auto; */
    justify-content: center;
    margin-left: auto; }
  .store__images {
    width: 90%; }
  .footer {
    height: 183px; }
    .footer__wrapper {
      flex-direction: column;
      text-align: center; }
    .footer__scroll {
      bottom: 182px; }
      .footer__scroll-02 {
        bottom: 182px; }
    .footer__right {
      width: 100%;
      position: relative;
      top: 25px; }
    .footer__left {
      width: 350px;
      text-align: center;
      position: relative;
      top: 10px;
      margin-right: 0; }
  .footer__scroll {
    display: none !important; }
    .footer__scroll-res {
      width: 55px;
      height: 55px;
      background-color: #2D5A6E;
      position: fixed;
      bottom: 182px;
      right: 0;
      z-index: 5;
      color: white;
      line-height: 55px; }
      .footer__scroll-res > img {
        position: relative;
        bottom: -12px;
        left: 21px;
        display: block; }
      .footer__scroll-res-span {
        position: relative;
        left: 13px;
        top: 0px;
        display: block; }
      .footer__scroll-res-res {
        display: block; }
        .footer__scroll-res-res-span {
          display: block; }
        .footer__scroll-res-res > img {
          display: block; }
  .footer__scroll-02 {
    display: none !important; }
    .footer__scroll-02-res {
      width: 55px;
      height: 55px;
      background-color: #2D5A6E;
      position: fixed;
      bottom: 122px;
      right: 0;
      z-index: 5;
      color: white;
      line-height: 55px; }
      .footer__scroll-02-res > img {
        position: relative;
        bottom: -12px;
        left: 21px;
        display: block; }
      .footer__scroll-02-res-span {
        position: relative;
        left: 13px;
        top: 0px;
        display: block; }
      .footer__scroll-02-res-res {
        display: block; }
        .footer__scroll-02-res-res-span {
          display: block; }
        .footer__scroll-02-res-res > img {
          display: block; }
  .main__txt {
    margin: 0 auto; }
    .main__txt-wrapper {
      flex-direction: column; }
  .main__text > p {
    margin: 0; }
  .main__des {
    padding-top: 20px;
    width: 100%;
    text-align: center;
    margin-left: 0; }
  .top__des {
    text-align: center;
    margin-left: 0; }
  .top__des02 {
    text-align: center;
    margin-left: 0; }
  .main-detaile__images {
    padding-top: 50px; } }

@media (max-width: 720px) {
  .box_one {
    flex-direction: column; }
  .box_one:nth-child(2) {
    flex-direction: column; }
  .box_one-images {
    width: 100%;
    justify-content: center; }
  .box_one-image01 > img {
    margin-left: -1%; }
  .box_one-image02 > img {
    margin-left: 3%; }
  .box_text {
    width: 100%;
    margin-left: 0;
    margin-top: 30px; }
  .box_one:nth-child(2) .box_text {
    margin-right: 0;
    margin-top: 30px; }
  .accordion_inner {
    height: 900px; }
  .content-row {
    flex-direction: column;
    width: 100%; }
    .content-row:nth-child(2) {
      flex-direction: column; }
  .slide-2__txt {
    width: 100%; }
  .slide-2__images {
    width: 100%;
    justify-content: center; } }

@media (max-width: 790px) {
  .btn__ichiran {
    padding: 10px 40px; } }

@media (max-width: 890px) {
  .section__title-wrapper {
    padding: 0 60px; }
  .section__enttl {
    margin-left: 10px; } }

@media (max-width: 600px) {
  .section__enttl-category {
    margin-left: 15px;
    font-size: 15px; }
  .hero__image-left::after {
    padding-top: 95%; }
  .hero__image-right::after {
    padding-top: 95%; }
  .icon {
    width: 90%; }
  .toggleButton-top-left {
    font-size: 23px;
    width: 80%; }
  .toggleButton-under-left {
    font-size: 23px;
    width: 80%; }
  .toggleButton-top {
    font-size: 23px;
    width: 80%; }
  .toggleButton-under {
    font-size: 23px;
    width: 80%; }
  .top__des02 {
    text-align: center;
    margin-left: 0; }
    .top__des02 > span {
      margin-right: 3px; }
    .top__des02-home {
      margin-right: 3px; }
    .top__des02-ichiran {
      margin-right: 3px; }
  .main-detaile__title-wrapper {
    text-align: left; }
  .main-detaile__images-wrapper {
    flex-direction: column; }
  .main-detaile__images01 {
    width: 100%;
    margin-bottom: 20px; }
  .main-detaile__images02 {
    width: 100%; }
  .accordion__title {
    width: 60%; }
  .accordion_inner {
    height: 930px; }
  .accordion_title-wrapper {
    width: 300px; }
  .box_one-image01 > img {
    height: auto; }
  .box_one-image02 > img {
    height: auto; } }

@media screen and (max-width: 660px) {
  .main__images {
    width: 40%; }
  .main__txt {
    width: 90%; } }

@media (max-width: 480px) {
  .section__title-wrapper-category {
    height: 100px; }
  .section__title-left-category {
    display: block; }
    .section__title-left-category > img {
      position: relative;
      top: 20px;
      right: 35px; }
  .section__jattl-category {
    display: block;
    position: relative;
    bottom: 30px; }
  .section__enttl-category {
    display: block;
    top: -30px;
    left: 20px; }
  .mobile-menu__span1 {
    font-size: 25px; }
  .mobile-menu__span2 {
    font-size: 16px; }
  .mobile-menu__li {
    height: 70px; }
    .mobile-menu__li > a {
      width: 60%;
      text-align: left;
      margin: 0 auto; }
  .logo__img {
    width: 80%; }
  .header__inner {
    margin-left: 20px; }
  .hero__image-left::after {
    padding-top: 115%; }
  .hero__image-right::after {
    padding-top: 115%; }
  .category__parallax {
    margin-top: 40px;
    margin-bottom: 20px; }
    .category__parallax::after {
      padding-top: 55%; }
  .store__info {
    width: 80%;
    margin: 0 auto; }
    .store__info > table {
      position: relative;
      bottom: 30px; }
  .store__des {
    top: 30px; }
  .store__ttl {
    display: block;
    color: #2d5a6e; }
  .store__content {
    display: block;
    padding-bottom: 10px; }
  .service__wrapper {
    height: 450px; }
  .icon__txt > span {
    font-size: 20px;
    position: relative;
    left: 20px; }
  .icon__p {
    position: relative;
    left: 20px; }
  .icon__left > img {
    left: 25px; }
  .main__inner {
    width: 100%; }
  .main__txt {
    width: 80%; }
  .main__images {
    width: 80%; }
    .main__images > img {
      height: 180px; }
  .main__panel {
    height: 180px; }
    .main__panel:first-child {
      margin-top: 30px; }
  .pager .pagination {
    padding: 30px; }
  .top__news::after {
    padding-top: 70%; }
  .top__des {
    margin-left: 50px;
    margin-top: 20px; }
  .accordion__title {
    width: 80%; }
  .accordion__jattl {
    font-size: 22px; }
  .accordion__enttl {
    font-size: 33px; } }

@media (max-width: 440px) {
  .mobile-menu__li > a {
    width: 70%; }
  .section__enttl {
    margin-left: 5px;
    font-size: 15px; }
  .section__jattl {
    margin-left: 10px;
    font-size: 33px; }
    .section__jattl-category {
      font-size: 33px; } }

@media (max-width: 390px) {
  .section__title-wrapper {
    padding: 0 70px; } }
