@media (max-width:600px) {
    .section {

        &__enttl {
            &-category {
                margin-left: 15px;
                font-size: 15px;
               
            }
        }
    }
    .hero {
        &__image {
            &-left {
                &::after {
                    padding-top: 95%;
                }
            }
            &-right {
                &::after {
                    padding-top: 95%;
                }
            }
        }
    }
    .icon {
        width: 90%;
        
    }
    .toggleButton-top-left {
        font-size: 23px;
        width: 80%;
    }
    .toggleButton-under-left {
        font-size: 23px;
        width: 80%;
    }

    .toggleButton-top {
        font-size: 23px;
        width: 80%;
    }
    .toggleButton-under {
        font-size: 23px;
        width: 80%;
    }

    .top {
        &__des02 {
            text-align: center;
            margin-left: 0;

            & > span {
                margin-right: 3px;
            }
            &-home {
                margin-right: 3px;
            }
            &-ichiran {
                margin-right: 3px;
            }
        }
    }
    .main-detaile {
        &__title {
            &-wrapper {
                text-align: left;
            }
        }
        &__images {
            &-wrapper {
                flex-direction: column;
            }
        }
        &__images01 {
            width: 100%;
            margin-bottom: 20px;
        }
        &__images02 {
            width: 100%;
        }
    }

    .accordion {
        &__title {
            width: 60%;
        }
    }
    .accordion_inner {
        height: 930px;
      }
    .accordion_title-wrapper {
        width: 300px;
    }
    .box_one-image01 {
        & > img {
            height: auto;
        }
    }
    .box_one-image02 {
        & > img {
            height: auto;
        }
    }
}

@media screen and (max-width:660px) {
     .main {
         &__images {
             width: 40%;
         }
         &__txt {
             width: 90%;
         }
     }
}